import React, { useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { Collapse } from "react-collapse";
import { CollapsibleButton } from "../Buttons";

const Collapsible = ({ children, title }) => {
  const height = "100%";
  const [opened, setOpened] = useState(false);
  return (
    <Flex justify='center' flexDir='column' align='center' w='100%' mb='2rem'>
      <CollapsibleButton
        onClick={() => setOpened(!opened)}
        title={title}
      ></CollapsibleButton>
      <Collapse
        initialStyle={{ height: "0px", overflow: "hidden" }}
        isOpened={opened}
      >
        <Box border='1px solid #ccc' width='100%' p='4rem' style={{ height }}>
          {children}
        </Box>
      </Collapse>
    </Flex>
  );
};

export default Collapsible;
