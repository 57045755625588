import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import "@splidejs/react-splide/css";
import * as React from "react";
import { Parallax } from "react-parallax";
import { Layout, Seo } from "../components";
import Collapsible from "../components/Collapsible";
import image from "../images/01.png";
import "../styles/screen.scss";

const seoBundle = {
  title: "Boise Steel Erectors",
  subtitle: "About JMF Steel Erectors",
  description: "Frequently Asked Questions",
  slug: "/",
};

const FAQPage = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Box maxW='full'>
        <Parallax
          blur={0}
          bgImage={image}
          bgImageAlt='modern architecture house'
          strength={150}
        >
          <Flex
            minH={["400px", null, "600px", "800px"]}
            bg='rgba(0,0,0,0.4)'
            justify='center'
            flexDir='column'
          >
            <Flex
              flexDir='column'
              justify='center'
              align='center'
              h='100%'
              px={["20px", null, "40px", "80px"]}
              mt={["6rem", null, null, "0px"]}
              maxW='1450px'
              mx='auto'
            >
              <Heading
                as='h1'
                color='#fff'
                fontWeight={800}
                fontSize={["2em", null, "4em", "6em"]}
                fontFamily={"heading"}
                lineHeight='120%'
                textAlign='center'
              >
                Frequently Asked Questions
              </Heading>
              <Text
                color='#fff'
                textAlign={"center"}
                fontSize={["2rem", null, "2.5rem", "3rem"]}
                lineHeight='120%'
              >
                If you don't find your answer below, feel free to give us a
                call.
              </Text>
            </Flex>
          </Flex>
        </Parallax>
      </Box>

      <Flex my={["30px", null, null, "50px"]}>
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            null,
            null,
            null,
            "repeat(2, 1fr)",
          ]}
          gap='4rem'
          px={["2rem", "4rem"]}
          width='100%'
          maxW='1450px'
          m='auto'
        >
          <GridItem width='100%'>
            <Heading as='h2' mb='10px'>
              Services Questions
            </Heading>
            <Collapsible title='How Do I Go About Getting an Estimate?'>
              <Text>
                <ol>
                  <li>
                    1. Decide on a general design for your project. Then call a
                    steel manufacturer. Some great local companies would be{" "}
                    <a href='https://rmsteel.com'>R & M Steel</a>{" "}
                    <a href='tel:2084541800'>(208) 454-1800</a> or{" "}
                    <a href='https://www.crolledsteel.com'>Cold Rolled Steel</a>{" "}
                    <a href='tel:2084557477'>(208) 455-7477</a>. Both offer
                    excellent service and products.
                  </li>
                  <br />
                  <li>
                    2. Once you have a general idea of the design you want to
                    order, the second step would be to talk with Jake. He can
                    look at your design and give some of his recommendations.
                  </li>
                  <br />
                  <li>
                    3. Once you know more about the design and where it will be
                    going, JMF Construction can then schedule an actual written
                    estimate.
                  </li>
                </ol>
              </Text>
              <Text>
                If you feel you would like more information about the process
                just to get started, don't hesitate to reach out to us.
              </Text>
            </Collapsible>
            <Collapsible title='What Part of the Process Does Your Service Entail?'>
              <Text>
                We are not a steel manufacturer. We are a steel erector. That
                means you will need to design a steel building with a
                manufacturer for us to assemble. We can handle laying the
                foundation as well. Some customers prefer to use their own
                concrete company for the construction. However, many times when
                using a third party concrete company, the bolt placement can be
                off or other issues can arise.
              </Text>
            </Collapsible>
            <Collapsible title='What Types of Steel Structures Do You Erect?'>
              <Text>
                We construct any structure that is pre-engineered for
                Commercial, Agricultural, and Residential use. We do projects of
                all sizes, from small garages up to large warehouses or riding
                arenas.
              </Text>
            </Collapsible>
            <Collapsible title='Can You List Some References for JMF Construction?'>
              <Text>
                Certainly! We've erected structures for Farm Equipment USA
                (Fruitland and Jerome, Idaho), Simplot Western Stockmen
                (Caldwell, Idaho), CSDI (Nampa, Idaho), and Champion Produce
                (Parma, Idaho), LMAC Cattle Company (Midvale, Idaho), among
                others.
              </Text>
            </Collapsible>
            <Collapsible title='What Are the Benefits of Steel Structures Over Wood?'>
              <Text>
                There are actually lots of benefits that come with using steel.
                <ol>
                  <li>
                    1. Engineering is done in-house with the steel manufacturer
                  </li>
                  <li>2. Steel is much stronger than wood</li>
                  <li>
                    3. Because steel construction doesn't require trusses,
                    there's a lot more headroom.
                  </li>
                  <li>4. Steel has a much longer free span than wood.</li>
                  <li>5. Steel structures have better insulation potential</li>
                </ol>
              </Text>
            </Collapsible>
            <Collapsible title='Are You Licensed and Insured?'>
              <Text>
                Yes! We are licensed and insured. Our Idaho Contractor Number is
                RCT-51940
              </Text>
            </Collapsible>
          </GridItem>
          <GridItem>
            <Heading as='h2' mb='10px'>
              Process Questions
            </Heading>
            <Collapsible title='How Do I Start the Process?'>
              <Text>
                The first step is to pick a structure design from a steel
                manufacturer. Don't hesitate to give us a call if you feel
                overwhelmed by the process.
              </Text>
            </Collapsible>
            <Collapsible title='What is the Usual Lead Time for a Project Like This?'>
              <Text>
                It can take about 6 - 12 weeks to recieve a package from the
                manufacturer. Once the package comes in, things can move very
                quickly. This is all dependent on schedule, weather, concrete,
                and time of the year.
              </Text>
            </Collapsible>
            {/* <Collapsible title='Can I make Changes to the Design Once the Build Starts?'>
              <Text>
                Yes. However, this will also require an adjustment in the price
                and time frame for the project to reach completion.
              </Text>
            </Collapsible> */}
            <Collapsible title='Do I Need a Permit?'>
              <Text>
                Yes, most likely you will need a building permit. JMF doesn't
                obtain building permits. You can check in with your county to
                get that process started.
              </Text>
            </Collapsible>
            <Collapsible title='How Does Communication Work During the Process?'>
              <Text>
                JMF makes sure to call clients back as soon as possible. A short
                feedback loop is one of the reasons for the success on all of
                our projects.
              </Text>
            </Collapsible>
            <Collapsible title='Is There Anything I Should be Aware of as I Begin the Process?'>
              <Text>
                Yes. This process isn't one-size-fits-all. It is super helpful
                for JMF Construction to know the purpose of your building for us
                to better guide the process. Many times we can have been able to
                bypass issues or save our customers money through our
                recommendations.
              </Text>
            </Collapsible>
          </GridItem>
        </Grid>
      </Flex>
    </Layout>
  );
};

export default FAQPage;
